







































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {SuiviAutresReglement, Tiers} from '@/models';

import CrudSuiviAutresReglementEdit from '@/components/Crud/SuiviAutresReglement/Edit.vue';

@Component({
	components: {
		CrudSuiviAutresReglementEdit,
	}
})
export default class CrudSuiviAutresReglementEditDialog extends Vue {
	@InOut({ type: SuiviAutresReglement  , isVModel: true }) private value!: SuiviAutresReglement;

	@Emit()
	private onSubmit(contact: SuiviAutresReglement) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
