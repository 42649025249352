



















































































































	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
  import {BuyType, Contrat, Prestation, PrestationType, Tiers, TiersType} from '@/models';
	import InputDatePicker from '@/components/Input/DatePicker.vue';
	import CrudPrestationSearch from '@/components/Crud/Prestation/Search.vue';
  import {Watch} from 'vue-property-decorator';
  import storeContrat from '@/stores/modules/contrat';
  import storePrestation from '@/stores/modules/prestation';
  import CrudChauffeurSearch from '@/components/Crud/Chauffeur/Search.vue';
  import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
  import CrudTiersEditDialog from '@/components/Crud/Tiers/EditDialog.vue';
  import {InOut} from 'vue-inout-prop-decorator';
  import InputPrestationType from '@/components/Input/PrestationType.vue';
	import InputEnumSelect from '@/components/Input/EnumSelect.vue';

	@Component({
		components: {
			InputDatePicker,
			InputPrestationType,
			InputEnumSelect,
			CrudPrestationSearch,
			CrudChauffeurSearch,
      CrudTiersSearch,
      CrudTiersEditDialog,
		}
	})
	export default class CrudContratRapideEdit extends AbstractEditVue<Contrat> {

    private step: number = 1;
		private addPrestationList: Prestation[] = [];
		private editProprietaire: Tiers = null;
		private TiersType = TiersType;
		private BuyType = BuyType;
		private selectedProprietaire: Tiers = null;
    private prestationType:PrestationType = null;
    private buyType:BuyType = null;

		public constructor() {
			super(
				storeContrat,
				'Prestation modifié',
				'Prestation créé',
			);
		}

    private onCreateTiers(): void {
			this.editProprietaire = new Tiers();
			this.editProprietaire.type = TiersType.PROPRIETAIRE;
		}
    
		private onCreatedTiers(tiers: Tiers): void {
			this.step = 2;
			this.$forceUpdate();
		}

		@Emit()
		private onNext() {
		}

		protected async onSubmit(): Promise<void> {
      this.item.idTiers = this.selectedProprietaire.id;
      this.item.idPrestationType = this.prestationType.id;
      this.item.TypeAchat = this.buyType;

			await super.onSubmit();
		}

		public async mounted(): Promise<void> {
      await super.mounted();

    }


		protected async saved(): Promise<void> {
			try {
        console.log("Saved");
			} catch(e) {
				console.error(e);
			}
		}
		
	}
