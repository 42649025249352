
































































import {Prop, Watch} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Tiers} from '@/models';

import storeSuiviAutresReglement from '@/stores/modules/suiviAutresReglement';

import InputPhone from '@/components/Input/Phone.vue';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
import {SuiviAutresReglement, ReglementType} from "@/models";
import InputEnumSelect from '@/components/Input/EnumSelect.vue';
import InputDatePicker from '@/components/Input/DatePicker.vue';

@Component({
	components: {
		InputPhone,
		CrudTiersSearch,
		InputEnumSelect,
		InputDatePicker,
	}
})
export default class CrudSuiviAutresReglementEdit extends AbstractEditVue<SuiviAutresReglement> {
	private ReglementType = ReglementType;

	public constructor() {
		super(
			storeSuiviAutresReglement,
			'Reglement modifié',
			'Reglement créé',
		);
	}

	protected async refresh(): Promise<void> {
		await super.refresh();
	}

}
