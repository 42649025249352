




























































































































































































	import {Component} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator";
	import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';
	import {Plateforme, Token, CubageLivraisonPlateforme, Livraison, CubagePlateforme, Chantier} from '@/models';
	import {ResultType} from "@/shared/xhttp";

	import storePlateforme from '@/stores/modules/plateforme';
	import storeLoader from "@/stores/modules/loader";

	import CrudTiersShow from '@/components/Crud/Tiers/Show.vue';
	import CrudTiersEdit from '@/components/Crud/Tiers/Edit.vue';
	import Checked from '@/components/Checked.vue';
	import CrudTiersShowReduce from '@/components/Crud/Tiers/ShowReduce.vue';
	import CrudPlateformeShowGeneral from "@/components/Crud/Plateforme/ShowGeneral.vue";
	import DialogConfirm from "@/components/Dialog/Confirm.vue";
	import storeToken from "@/stores/modules/token";
	import {VueHelper} from "@/shared/utils";
	import CrudCubageLivraisonPlateformeList from "@/components/Crud/CubageLivraisonPlateforme/List.vue";
	import CrudCubageLivraisonPlateformeEditCreateDialog from "@/components/Crud/CubageLivraisonPlateforme/EditDialogCreate.vue";
	import CrudCubagePlateformeRestantList from "@/components/Crud/CubagePlateformeRestant/List.vue";
	import CrudCubagePlateformeList from "@/components/Crud/CubagePlateforme/List.vue";
	import CrudCubagePlateformeListHistory from "@/components/Crud/CubagePlateforme/ListHistory.vue";
	import { Emit } from 'vue-property-decorator';
	import storeCubagePlateformeRestant from "@/stores/modules/cubagePlateformeRestant";
	import storeCubagePlateforme from "@/stores/modules/cubagePlateforme";
	import storeCubageLivraisonPlateforme from "@/stores/modules/cubageLivraisonPlateforme";
	import CrudPlateformeEditDialog from "@/components/Crud/Plateforme/EditDialog.vue";
    import CrudPlateformeEditDialogCases from '@/components/Crud/Plateforme/EditDialogCases.vue';

	import CrudLivraisonEditDialog from '@/components/Crud/Livraison/EditDialog.vue';
	import CrudLivraisonEditDialogCreate from '@/components/Crud/Livraison/EditDialogCreate.vue';
	import storeLivraison from '@/stores/modules/livraison';
	import {PaginatorState} from '@/shared/utils';
	import CrudCubagePlateformeEditDialogCreateFromPlateforme from "@/components/Crud/CubagePlateforme/EditDialogCreateFromPlateforme.vue";

	@Component({
		components: {
			CrudTiersShow,
			CrudTiersEdit,
			Checked,
			CrudTiersShowReduce,
			DialogConfirm,
			CrudPlateformeShowGeneral,
			CrudCubageLivraisonPlateformeList,
			CrudCubageLivraisonPlateformeEditCreateDialog,
			CrudCubagePlateformeList,
			CrudCubagePlateformeRestantList,
			CrudCubagePlateformeListHistory,
			CrudPlateformeEditDialog,
			CrudPlateformeEditDialogCases,
			CrudLivraisonEditDialog,
			CrudLivraisonEditDialogCreate,	
			storeLivraison,
			CrudCubagePlateformeEditDialogCreateFromPlateforme,
		}
	})
	export default class CrudPlateformeShow extends AbstractShowVue<Plateforme> {

		@Stored(() => storeLoader)    private loading: boolean;
		@Stored(() => storeToken)     private token: Token;

		private createCubageLivraisonPlateforme: CubageLivraisonPlateforme = null;
		private storeCubageLivraisonPlateforme = storeCubageLivraisonPlateforme
		private storeCubagePlateformeRestant = storeCubagePlateformeRestant
		private storeCubagePlateforme = storeCubagePlateforme
		private editPlateforme: Plateforme = null;
		private editCases: Plateforme = null;

		private loadingEnd: boolean = false;

		private editLivraison: Livraison = null;
		private newCubagePlateforme: CubagePlateforme = null;

		private get hrefPdf(): string {
			return `${process.env.VUE_APP_BACK_BASE_URL}/pdf/plateforme/${this.item.id}?token=${this.token.id}`;
		}

		public constructor() {
			super(storePlateforme);
		}
		
		@Emit()
		private createCubagePlateforme(): void {
			this.newCubagePlateforme = new CubagePlateforme();
			this.newCubagePlateforme.plateforme = this.item;
			this.newCubagePlateforme.sansMarquage = true;
			this.newCubagePlateforme.coef = 0.9;
			this.newCubagePlateforme.coefStere = 0.63;
		}

		private refreshCubagePlateforme()
		{
			this.refreshList();
		}

		@Emit()
		private createLivraison(): void {
			//console.log(oCubageChantier);
			this.editLivraison = new Livraison();
		}

		private async addLivraison(): Promise<void> {
			storeLoader.commit('push');
			try {
				/*for (let livraison of this.addLivraisonList) {
					livraison = await storeLivraison.dispatch('get', livraison.id);
					livraison.plateforme = this.item;
					console.log(livraison.plateforme);
					await storeLivraison.dispatch('put', livraison);
				}
				await Promise.all([
					storeLivraison.dispatch('getC', { filters: true }),
					storePlateforme.dispatch('get', this.item.id),
				]);
				this.addLivraisonList = [];
				this.addLivraisonDialog = false;*/
			} catch (e) {
				console.error(e);
				this.notify('Une erreur est survenu', 'error');
			}
			storeLoader.commit('pop');
		}
		
		private async removeLivraison(): Promise<void> {
			storeLoader.commit('push');
			try {
				/*const removeLivraisonConfirm = await storeLivraison.dispatch('get', this.removeLivraisonConfirm.id);
				removeLivraisonConfirm.plateforme = null;
				await storeLivraison.dispatch('put', removeLivraisonConfirm);
				await Promise.all([
					storeLivraison.dispatch('getC', { filters: true }),
					storePlateforme.dispatch('get', this.item.id),
				]);
				this.removeLivraisonConfirm = null;*/
			} catch (e) {
				this.notify('Une erreur est survenu', 'error');
			}
			storeLoader.commit('pop');
		}

		@Emit()
		private AddCubageLivraison(): void {
			var oLivraison  = (storeLivraison.state as PaginatorState).current;
			storeLivraison.state.CounterCreation = 1;

			let cubage = new CubageLivraisonPlateforme();
			cubage.plateforme = this.item;
			cubage.date = oLivraison.date;
			cubage.livraison = oLivraison;
			cubage.bl = oLivraison.bl;
			cubage.numfacture = oLivraison.numfacture;
			cubage.scierie = oLivraison.scierie;
			cubage.chauffeur = oLivraison.chauffeur;
			cubage.coef =0.9;
			cubage.coefStere =0.63;
			this.createCubageLivraisonPlateforme = cubage;
		}

		public async refreshList(): Promise<void> {
			await storeCubagePlateformeRestant.dispatch('getC', { filters: true })
			await storeCubageLivraisonPlateforme.dispatch('getC', { filters: true })
			await storeCubagePlateforme.dispatch('getC', { filters: true })
		}
	}
