








































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Contrat} from '@/models';

import CrudContratRapideEdit from '@/components/Crud/Contrat/EditContratRapide.vue';

@Component({
	components: {
		CrudContratRapideEdit,
	}
})
export default class CrudEditContratRapideDialog extends Vue {
	@InOut({ type: Contrat  , isVModel: true }) private value!: Contrat;
	@Prop({ type: Boolean, default: false}) showOnClose: boolean;
	@Prop({ type: Boolean, default: false}) noRefresh: boolean;

	@Emit()
	private onSubmit(contrat: Contrat) {
        //console.log("Submit dialog");
		if (this.showOnClose) {
			this.$router.push({name: 'contrat-show', params: { id : this.value.id.toString() }});
		}
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
	}
}
