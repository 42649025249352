


























































































































































	import {Prop, Watch} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator";
	import {Livraison, CubageLivraison, CubageLivraisonPlateforme, Plateforme} from "@/models";
	import {ArrayHelper} from "@/shared/utils";
  	import Checked from '@/components/Checked.vue';
	import {ResultType} from '@/shared/xhttp';

	import storeCubageLivraisonPlateforme, {CubageLivraisonPlateformeFilter} from '@/stores/modules/cubageLivraisonPlateforme';

	import CrudList from '@/components/Crud/List.vue';
	import TableHead from '@/components/Paginate/TableHead.vue';
	import TableCell from '@/components/Paginate/TableCell.vue';
	import CrudCubageLivraisonPlateformeEditDialog from "@/components/Crud/CubageLivraisonPlateforme/EditDialog.vue";
	import CrudCubageLivraisonPlateformeEditDispatchDialog from "@/components/Crud/CubageLivraisonPlateforme/EditDispatchDialog.vue";
	import CrudDeleteDialog from "@/components/Crud/DeleteDialog.vue";
	import storeCubagePlateformeRestant from '@/stores/modules/cubagePlateformeRestant';
	import storeCubagePlateforme from '@/stores/modules/cubagePlateforme';
	import CrudCubageLivraisonListRecapDialog from '@/components/Crud/CubageLivraison/ListRecapDialog.vue';
	import CrudLivraisonEditDialog from '@/components/Crud/Livraison/EditDialog.vue';

	@Component({
		components: {
			CrudList,
			TableHead,
			TableCell,
			Checked,
			CrudCubageLivraisonPlateformeEditDialog,
			CrudCubageLivraisonPlateformeEditDispatchDialog,
			CrudDeleteDialog,
			CrudCubageLivraisonListRecapDialog,
			CrudLivraisonEditDialog,
		}
	})
	export default class CrudCubageLivraisonPlateformeList extends Vue {

		@Prop({ type: Boolean   , default: true  }) public paginate!: boolean;
		@Prop({ type: Boolean   , default: false }) public dense!   : boolean;
		@Prop({ type: [ Plateforme, Boolean ] , default: false }) public plateforme: Plateforme;

		@Stored(() => storeCubageLivraisonPlateforme) private filters!: CubageLivraisonPlateformeFilter;
		@Stored(() => storeCubageLivraisonPlateforme)
		private cubageLivraisonPlateformes!: ResultType<CubageLivraisonPlateforme>;

		private editCubageLivraisonPlateforme: CubageLivraisonPlateforme = null;
		private editLivraisonSeule: Livraison = null;

		private showCubageLivraison: CubageLivraison = null;
		private dispatchCubage: CubageLivraisonPlateforme = null;
		private deletedCubageLivraisonPlateforme: CubageLivraisonPlateforme = null;
		private storeCubageLivraisonPlateforme = storeCubageLivraisonPlateforme;
		private storeCubagePlateformeRestant = storeCubagePlateformeRestant
		private storeCubagePlateforme = storeCubagePlateforme

		public get show(): boolean {
			return this.plateforme !== null;
		}

		public mounted(): void {
			this.watcher();
		}

		private async LanceEditLivraison(item): Promise<void> 
		{
			var oNew = new Livraison();
			console.log(item.livraison.id);
			oNew.id = item.livraison.id;
			this.editLivraisonSeule = oNew; // await storeLivraison.dispatch('get', item.livraison.id);
		}

		@Watch('plateforme')
		public watcher(): void {
			if (this.plateforme) {
				this.filters.clear();
				this.filters.plateforme = [ this.plateforme ];
			} else {
				this.filters.plateforme = [];
			}
		}

		private async refresh(): Promise<void> {
			await storeCubageLivraisonPlateforme.dispatch('getC', { filters: true });
			await storeCubagePlateforme.dispatch('getC', { filters: true });
			await storeCubagePlateformeRestant.dispatch('getC', { filters: true });
		}

		private async distributeCubage(cubage): Promise<void> {
			await storeCubageLivraisonPlateforme.dispatch('distributeCubage', cubage)
		}

	}
