








































	import {Emit, Prop} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';
    import {Facture} from "@/models";

	import CrudFactureProprietaireEdit from '@/components/Crud/Facture/ProprietaireEdit.vue';

	@Component({
		components: {
			CrudFactureProprietaireEdit,
		}
	})
	export default class CrudFactureProprietaireEditDialog extends Vue {
		@InOut({ type: Facture  , isVModel: true }) private value!: Facture;

		@Emit()
		private onSubmit(parcelle: Facture) {
			this.onClose();
		}

		@Emit()
		private onClose() {
			if (this.value.id) 
				{
					if (
						this.$route.name !== 'facture-show' || 
						this.$route.params["id"] !== this.value.id.toString()
					) {
						this.$router.push({name: 'facture-show', params: { id : this.value.id.toString() }});
					}
				}

			this.value = null;
		}
	}
