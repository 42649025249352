





















































































































































































































import {Prop, Watch} from "vue-property-decorator";
import {Emit} from "vue-property-decorator";
import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {TiersType, BoisQuality, BoisSize, BoisType, Chantier, CubageLivraisonPlateforme, CubageChantier, Marquage} from '@/models';
import {ObjectHelper} from "@/shared/utils";
import InputDatePicker from '@/components/Input/DatePicker.vue';

import storeCubageLivraisonPlateforme from '@/stores/modules/cubageLivraisonPlateforme';
import storeCubageChantier from '@/stores/modules/cubageChantier';

import InputBoisType from '@/components/Input/BoisType.vue';
import InputBoisQuality from '@/components/Input/BoisQuality.vue';
import InputBoisSize from '@/components/Input/BoisSize.vue';
import InputMarquage from '@/components/Input/Marquage.vue';
import CrudChantierSearch from '@/components/Crud/Chantier/Search.vue';
import boisQuality from '@/router/boisQuality';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
import CrudChauffeurSearch from '@/components/Crud/Chauffeur/Search.vue';
import storeCubagePlateformeRestant from '@/stores/modules/cubagePlateformeRestant';

@Component({
	components: {
		InputDatePicker,
		InputBoisType,
		InputBoisQuality,
		InputBoisSize,
		InputMarquage,
		CrudChantierSearch,
		CrudTiersSearch,
		CrudChauffeurSearch,
	}
})
export default class CrudCubageLivraisonPlateformeEdit extends AbstractEditVue<CubageLivraisonPlateforme> {

	private TiersType = TiersType;
	private savedisabled: Boolean = true;
	private showMessage: Boolean = false;
	
	private cubageCopy = null;
	private plateformeSelected = null;
	private nextDisabled = true;
	private step = 1;
	private storeCubagePlateformeRestant = storeCubagePlateformeRestant;

	public constructor() {
		super(
			storeCubageLivraisonPlateforme,
			'Cubage modifié',
			'Cubage créé',
		);
	}

	public async mounted(): Promise<void> {
		await super.mounted();
		storeCubagePlateformeRestant.dispatch('getC', { filters: true });

		if (this.item.boisType != null)
		{
			this.step = 2;
		}
	}

	protected async refresh(): Promise<void> {
		await super.refresh();

		this.watcher();
	}

	protected async onSubmit(): Promise<void> {
		await super.onSubmit();
		this.step = 1;
	}

	public UpdateUI()
	{
		this.$forceUpdate();
	}

		private onChangeVolumeInner(): void {
			if (this.item.volumeInner) {
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangePrixStere(): void {
			this.$forceUpdate(); 
		}

		private onChangeVolumeOutter(): void {
			if (this.item.volumeOutter) {
				if (this.item.coef) {
					this.item.volumeInner = Math.round(this.item.volumeOutter * this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeVolumeStere(): void {
			if (this.item.volumeStere) {
				if (this.item.coefStere) {
					this.item.volumeInner = Math.round(this.item.volumeStere * this.item.coefStere * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate();
		}

		private onChangeCoef(): void {
			if (this.item.coef) {
				if (this.item.volumeOutter) {
					this.item.volumeInner = Math.round(this.item.volumeOutter * this.item.coef * 100 ) / 100;
				} 
				if (this.item.coefStere) {
					this.item.volumeStere = Math.round(this.item.volumeInner / this.item.coefStere * 100 ) / 100;
				} 
			}
			this.$forceUpdate(); 
		}

		private onChangeCoefStere(): void {
			if (this.item.coefStere) {
				if (this.item.volumeStere) {
					this.item.volumeInner = Math.round(this.item.volumeStere * this.item.coefStere * 100 ) / 100;
				} 
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} 
			}
			this.$forceUpdate(); 
		}

	public watcher(): void {
		if (this.item)
		{
			if	(this.item.prixM3 > 500) {
				this.savedisabled = true
				this.showMessage = true;
			} else {
				this.savedisabled = !this.item.chauffeur || !this.item.scierie;
				this.showMessage = false;
			}
		}
	}

	public watcherSave(): void {
			if (this.item)
			{
				if	(this.item.prixM3 > 500) {
					this.savedisabled = true
					this.showMessage = true;
				} else {
					this.savedisabled = false;
					this.showMessage = false;
				}
			}
		}

	public updateNext() {
		this.nextDisabled = !this.cubageCopy;
	}

	@Emit()
	private onNext() {
		if (this.cubageCopy) {
			this.item.boisQuality = this.cubageCopy.boisQuality
			this.item.boisType = this.cubageCopy.boisType
			this.item.boisSize = this.cubageCopy.boisSize
			this.item.case = this.cubageCopy.case
			this.step++
		}
	}
}
