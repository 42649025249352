





















































































































































	import {Prop, Watch} from "vue-property-decorator";
	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {TiersType, BoisQuality, BoisSize, BoisType, Chantier, CubageLivraisonPlateforme, CubageChantier, Marquage} from '@/models';
	import {ObjectHelper} from "@/shared/utils";
	import InputDatePicker from '@/components/Input/DatePicker.vue';

	import storeCubageLivraisonPlateforme from '@/stores/modules/cubageLivraisonPlateforme';
	import storeCubageChantier from '@/stores/modules/cubageChantier';

	import InputBoisType from '@/components/Input/BoisType.vue';
	import InputBoisQuality from '@/components/Input/BoisQuality.vue';
	import InputBoisSize from '@/components/Input/BoisSize.vue';
	import InputMarquage from '@/components/Input/Marquage.vue';
	import CrudChantierSearch from '@/components/Crud/Chantier/Search.vue';
	import boisQuality from '@/router/boisQuality';
	import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
    import CrudChauffeurSearch from '@/components/Crud/Chauffeur/Search.vue';
	import storeCubagePlateformeRestant from '@/stores/modules/cubagePlateformeRestant';

	@Component({
		components: {
			InputDatePicker,
			InputBoisType,
			InputBoisQuality,
			InputBoisSize,
			InputMarquage,
			CrudChantierSearch,
			CrudTiersSearch,
			CrudChauffeurSearch,
		}
	})
	export default class CrudCubageLivraisonPlateformeEditDispatch extends AbstractEditVue<CubageLivraisonPlateforme> {
		
		private TiersType = TiersType;
		private savedisabled: Boolean = true;
		private showMessage: Boolean = false;
		private cubageCopy = null;
		private nextDisabled = true;
		private step = 1;
		private storeCubagePlateformeRestant = storeCubagePlateformeRestant;
		private dateFormated = null;

		public constructor() {
			super(
				storeCubageLivraisonPlateforme,
				'Cubage modifié',
				'Cubage créé',
			);
		}

		public async mounted(): Promise<void> {
			await super.mounted();
			storeCubagePlateformeRestant.dispatch('getC', { filters: true });
			this.dateFormated = this.formatDate()
		}

		protected async refresh(): Promise<void> {
			await super.refresh();

			this.watcher();
		}

		protected async onSubmit(): Promise<void> {
			await super.onSubmit();
			this.step = 1;
		}

		private onChangeVolumeInner(): void {
			if (this.item.volumeInner) {
				if (this.item.coef) {
					this.item.volumeOutter = Math.round(this.item.volumeInner / this.item.coef * 100 ) / 100;
				} else
				if (this.item.volumeOutter) {
					this.item.coef = Math.round(this.item.volumeInner / this.item.volumeOutter * 100) / 100;
				}
			}
			this.watcher();
			this.$forceUpdate();
		}
		
		public watcher(): void {
			if (this.item)
			{
				if	(this.item.prixM3 > 500) {
					this.savedisabled = true
					this.showMessage = true;
				} else {
					this.showMessage = false;
					this.savedisabled = this.item.volumeApproximatif || !this.item.prixCamion || !this.item.volumeInner;
				}
			}
		}

		public formatDate() {
			if (!this.item.date) return null

			let date = this.item.date
			const day = String(date.getDate()).padStart(2, '0');
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const year = date.getFullYear();

			return `${day}/${month}/${year}`
		}

	}
