





















































































































































	import {Prop, Watch} from "vue-property-decorator";
	import {Stored} from 'vue-stored-prop-decorator';
	import {Component, Vue} from '@/shared/component';
	import {ResultType} from '@/shared/xhttp';
	import {Prestation} from '@/models';
	import {Timeout, WatchObjectProperties} from '@/shared/decorator';
	import axios from "axios";

	import { latLng } from "leaflet";
	import { LMap, LTileLayer, LControl, LTooltip, LPopup, LCircleMarker, LWMSTileLayer } from "vue2-leaflet";
	import storeToken from "@/stores/modules/token";
	import {Token, User} from "@/models";

	import storePrestation, {PrestationFilter} from '@/stores/modules/prestation';

	@Component({
		components: {
			LMap,
			LTileLayer,
			LControl,
			LTooltip,
			LPopup,
			LCircleMarker,
			'l-wms-tile-layer': LWMSTileLayer
		}
	})
	export default class PrestationMapView extends Vue {

		@Stored(() => storeToken) private token: Token;

		lat = 46.08014897989135;
		lng = 4.386728978266652;
		center;
		zoom = 13;
		url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		urlsat = 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';
		attribution =
			'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
		showMap = false;
		markers;
		subdomains = ["mt0", "mt1", "mt2", "mt3"];
		private showMapSatellite : boolean  = false;
		private showMapCadastre : boolean  = false;
		private opacitySat : number = 0;
		private opacityCadastre : number = 0;

		wmsLayer = {
        url: 'https://data.geopf.fr/wms-r/wms',
        name: 'Weather Data',
        visible: true,
		version : '1.3.0',
        format: 'image/png',
		request: 'GetCapabilities',
        layers: 'CADASTRALPARCELS.PARCELLAIRE_EXPRESS',
        transparent: true,
        attribution: 'Weather data &copy; 2012 <a href="https://mesonet.agron.iastate.edu/docs/nexrad_mosaic/">IEM Nexrad</a>',
      	};

		private filtreContrat  = '';
		private filtreCommune = '';
		private filtreClient  = '';
		private achatTerrain : boolean  = false;
		private ChantierBucheron : boolean  = false;

		private filtreContratText  = '';
		private filtreCommuneText = '';
		private filtreClientText  = '';
		private achatTerrainText = '';
		private ChantierBucheronText  = '';

		async mounted() {
			this.LoadData();
			this.center = latLng(this.lat, this.lng);
		}

		@Watch('showMapSatellite')
		onOpacitySatChanged(value: boolean, oldValue: boolean) {
			if (this.showMapSatellite == true)
				this.opacitySat = 1;
			else
				this.opacitySat = 0;
		}

		@Watch('showMapCadastre')
		onOpacityCadChanged(value: boolean, oldValue: boolean) {
			if (this.showMapCadastre == true)
				this.opacityCadastre = 1;
			else
				this.opacityCadastre = 0;
		}

		@Watch('filtreContrat')
		@Watch('filtreCommune')
		@Watch('filtreClient')
		@Watch('achatTerrain')
		@Watch('ChantierBucheron')
		onPropertyChanged(value: string, oldValue: string) {
			this.LoadData();
		}
		private async CalculFiltre()
		{
			if ((this.filtreContrat == '') || (this.filtreContrat == 'null') || (this.filtreContrat == null))
				this.filtreContratText = 'NULL';
			else
				this.filtreContratText = this.filtreContrat;

			if ((this.filtreCommune == '') || (this.filtreCommune == 'null') || (this.filtreCommune == null))
				this.filtreCommuneText = 'NULL';
			else
				this.filtreCommuneText = this.filtreCommune;

			if ((this.filtreClient == '') || (this.filtreClient == 'null') || (this.filtreClient == null))
				this.filtreClientText = 'NULL';
			else
				this.filtreClientText = this.filtreClient;

			if (this.achatTerrain == true)
				this.achatTerrainText = '1';
			else
				this.achatTerrainText = '0';

			if (this.ChantierBucheron == true)
				this.ChantierBucheronText = '1';
			else
				this.ChantierBucheronText = '0';
		}

		private async LoadData()
		{
			this.CalculFiltre();

			await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/prestations/maps/" + this.filtreContratText + "/" + this.filtreCommuneText + "/" + this.filtreClientText + "/" + this.achatTerrainText + "/" + this.ChantierBucheronText + "?token=" + this.token.id, {}).then(async response => {
				this.markers = await response.data;
			});

			this.$forceUpdate();
		}


		private ClearContrat() : void {
			this.LoadData();
		}

		private ClearCommune() : void {
			this.LoadData();
		}

		private ClearClient() : void {
			this.LoadData();
		}


	}
