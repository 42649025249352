













































































































































































































































































































































































































































	import {Emit} from "vue-property-decorator";
	import {Stored} from "vue-stored-prop-decorator";
	import {Component} from '@/shared/component';
	import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';
	import {Tiers, TiersType, Contact, Contrat, Token, SuiviAutresReglement} from '@/models';

	import storeTiers from '@/stores/modules/tiers';
	import storeToken from "@/stores/modules/token";
	import storeContact from "@/stores/modules/contact";
	import storeContrat from "@/stores/modules/contrat";
	import storeSuiviAutresReglement from "@/stores/modules/suiviAutresReglement";

	import CrudAddressShow from '@/components/Crud/Address/Show.vue';
	import CrudContactList from '@/components/Crud/Contact/List.vue';
	import CrudContratList from '@/components/Crud/Contrat/List.vue';
	import CrudTiersEditDialogLight from '@/components/Crud/Tiers/EditDialogLight.vue';
	import CrudTiersAdressesEditDialog from '@/components/Crud/Tiers/EditAdressesDialog.vue';
	import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';
	import CrudContactEditDialog from '@/components/Crud/Contact/EditDialog.vue';
	import CrudContratEditDialog from '@/components/Crud/Contrat/EditDialog.vue';
	import CrudTiersEditDialogPriceM3 from '@/components/Crud/Tiers/EditDialogPriceM3.vue'
	import CrudTiersEditDialogAttestation from '@/components/Crud/Tiers/EditDialogAttestation.vue'
	import CrudTiersReglementsList from '@/components/Crud/Tiers/ListReglements.vue'
	import CrudSuiviAutresReglementEditDialog from '@/components/Crud/SuiviAutresReglement/EditDialog.vue'


	@Component({
		components: {
			CrudAddressShow,
			CrudContactList,
			CrudContratList,
			CrudTiersEditDialogLight,
			CrudTiersAdressesEditDialog,
			CrudDeleteDialog,
			CrudContactEditDialog,
			CrudContratEditDialog,
			CrudTiersEditDialogPriceM3,
			CrudTiersEditDialogAttestation,
			CrudTiersReglementsList,
			CrudSuiviAutresReglementEditDialog,
		}
	})
	export default class CrudTiersShow extends AbstractShowVue<Tiers> {

		private imgPath: string = "";

		private editTiers: Tiers = null;
		private attestationTiers: Tiers = null;
		private editTiersAdresses: Tiers = null;

		private editContact: Contact = null;
		private deletedContact: Contact = null;
		private editTiersM3: Tiers = null;

		private isnewContrat : boolean = false;
		private editContrat: Contrat = null;
		private deletedContrat: Contrat = null;

		private editsuiviAutresReglement: SuiviAutresReglement = null;
		private deletedAutresReglement: SuiviAutresReglement = null;

		@Stored(() => storeToken)
		private token: Token;

		private storeContact = storeContact;
		private storeContrat = storeContrat;
		private storeSuiviAutresReglement = storeSuiviAutresReglement;
		
		private TiersType = TiersType;
		private headers = [];

		public constructor() {
			super(storeTiers);
			this.headers = [
					{text: 'Date', align: 'start', value: 'date'},
					{text: 'Motif', value: 'reference'},
					{text: 'Montant', value: 'montant'},
					{text: 'Actions', value: 'actions2', sortable: false, filterable: false},
				];
		}

		public async mounted(): Promise<void> {
			storeContact.state.filters.clear();
			storeContrat.state.filters.clear();
			await super.mounted();

		}

		public async refresh(value: any): Promise<void> {
			await super.refresh(value);

			if (this.item !== null)
			{
				if (this.item.type === TiersType.PROPRIETAIRE)
				{
				this.imgPath = "/img/files/proprietaire.jpg";
				}
				else
				{
				this.imgPath = "/img/files/scierie.jpg";
				}

				if (this.$route.name === 'tiers-show') {
					this.$route.meta.title = this.item.type === TiersType.PROPRIETAIRE ? 'Détails  d\'un propriétaire' : 'Détails  d\'une scierie';
				}
			}

		}

		private onCreateAutresReglement()
		{
			var newsuiviAutresReglement = new SuiviAutresReglement();
			newsuiviAutresReglement.tiers = this.item;
			console.log(newsuiviAutresReglement.tiers);

			this.editsuiviAutresReglement = newsuiviAutresReglement;
		}

		@Emit()
		private onCreateContact(): void {
			this.editContact = new Contact();
			this.editContact.tiers = this.item;
		}

		@Emit()
		private onCreateContrat(): void {
			this.isnewContrat = true;
			this.editContrat = new Contrat();
			this.editContrat.tiers = this.item;
		}

		private async refreshContacts(): Promise<void> {
			await Promise.all([
				storeContact.dispatch('getC', { filters: true }),
				this.refresh(this.value),
			]);
		}

		private async refreshContrats(): Promise<void> {
			this.isnewContrat = false;
			await Promise.all([
				storeContrat.dispatch('getC', { filters: true }),
				this.refresh(this.value),
			]);
		}

		formatDate(dateString) {
			if (dateString == '') return '';
			if (dateString == null) return '';

            const date = new Date(dateString);
                // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', { month: 'long', day: 'numeric', year: 'numeric', }).format(date);
        }
	}
