























































































































































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from "vue-stored-prop-decorator";
import {Component, Vue} from '@/shared/component';
import {Chantier, Contrat, Prestation} from '@/models';
import DialogConfirm from '@/components/Dialog/Confirm.vue';

import storePrestation, {PrestationFilter, PrestationState} from '@/stores/modules/prestation';

import Checked from '@/components/Checked.vue';
import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';
import {Store} from "vuex";
import {PaginatorState} from "@/shared/utils";
import {AscDesc, ResultType} from '@/shared/xhttp';

@Component({
	components: {
		CrudList,
		TableHead,
		TableCell,
		Checked,
		DialogConfirm,
	}
})
export default class CrudPrestationList extends Vue {

	@Prop({type: Boolean, default: true  }) public paginate!: boolean;
	@Prop({type: Boolean, default: false }) public dense!   : boolean;

	@Prop({type: [ Boolean, Contrat ] , default: false }) public contrat! : Contrat|boolean;
	@Prop({type: [ Boolean, Chantier ], default: false }) public chantier!: Chantier|boolean;
	@Prop({type: Store                , default: null  }) public store!   : Store<PrestationState>;

	public get list(): ResultType<Prestation> { return this.storePrestation.state["prestations"]; }

	private markPaidConfirm: boolean = false;
	private markPaidPrestation: Prestation = null;
	private markUnPaidConfirm: boolean = false;
	private markUnPaidPrestation: Prestation = null;
	private removePrestation: Prestation = null;

	private search = '';
	private headers = [
			{ text: 'Contrat', align: 'start', value: 'contrat.numero' },
			{ text: 'Commune', value: 'commune' },
			{ text: 'Client', value: 'client' },
			{ text: 'Type', value: 'prestationType' },
			{ text: 'Coupe', value: 'buyType' },
			{ text: '', value: 'action2' },
			{ text: 'Limite coupe', value: 'dtEnd' },
			{ text: 'Cubage estimé', value: 'cubageEstimatif' },
			{ text: 'Réglé', value: 'montantRegler' },
			{ text: 'Action', value: 'action' },
			];

	private get filters(): PrestationFilter {
		return this.storePrestation.state.filters;
	}

	private get show(): boolean {
		return (this.contrat === false || !!this.contrat) && (this.chantier === false || !!this.chantier);
	}

	private get storePrestation(): Store<PrestationState> {
		return this.store ? this.store : storePrestation;
	}

	private mounted(): void {
		this.contratWatcher();
	}
	
	@Watch('contrat')
	@Watch('chantier')
	private contratWatcher(): void {
		this.filters.clear();
		if (this.contrat) {
			this.filters.contrat = [ <Contrat>this.contrat ];
		}
		if (this.chantier) {
			this.filters.chantier = [ <Chantier>this.chantier ];
		}

		this.refresh();
	}

	public async refresh(): Promise<void> {
		if (this.show && this.storePrestation) {
			await this.storePrestation.dispatch('getC', { filters: true });
		}
	}

	private bodyClass(item: Prestation): string {
		return item.totalPriceSale - item.totalPriceBuy >= 0 ? 'prestation-sale' : 'prestation-buy';
	}

	private async onRemovePrestation(): Promise<void> {
		try {
			const prestation: Prestation = await storePrestation.dispatch('get', this.removePrestation.id);
			prestation.chantier = null;
			await storePrestation.dispatch('put', prestation);
			await storePrestation.dispatch('getC', { filters: true });
			this.refresh();
		} catch (e) {
			console.error(e);
			//this.notify('Une erreur est survenu', 'error');
		}
	}
	
	private prestationMarkPaidConfirme(prestation: Prestation): void {
		this.markPaidConfirm = true;
		this.markPaidPrestation = prestation;
	}

	private async prestationMarkUnpaid(): Promise<void> {
		await storePrestation.dispatch('putMarkUnpaid', this.markUnPaidPrestation);
		await Promise.all([
			storePrestation.dispatch('getC', { filters: true }),
		]);
	}

	private prestationMarkUnpaidConfirme(prestation: Prestation): void {
		this.markUnPaidConfirm = true;
		this.markUnPaidPrestation = prestation;
	}

	private async prestationMarkPaid(): Promise<void> {
		await storePrestation.dispatch('putMarkPaid', this.markPaidPrestation);
		await Promise.all([
			storePrestation.dispatch('getC', { filters: true }),
		]);
	}
}
