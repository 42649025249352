






























































































































































































































	import {Prop, Watch} from "vue-property-decorator";
	import {Stored} from 'vue-stored-prop-decorator';
	import {Component, Vue} from '@/shared/component';
	import {ResultType} from '@/shared/xhttp';
	import {Timeout, WatchObjectProperties} from '@/shared/decorator';
	import axios from "axios";

	import { icon, latLng } from "leaflet";
	import { LMap, LMarker, LTileLayer, LControl, LTooltip, LPopup, LCircleMarker, LIcon, LWMSTileLayer  } from "vue2-leaflet";
	import storeToken from "@/stores/modules/token";
	import {Token, User} from "@/models";
	import storeCubageChantierRestantForSearchChantier, {CubageChantierRestantForSearchChantierFilter} from "@/stores/modules/cubageChantierRestantForSearchChantier";
	import storeBoisType from "@/stores/modules/boisType";
	import storeBoisQuality from "@/stores/modules/boisQuality";
	import storeBoisSize from "@/stores/modules/boisSize";
	import InputBoisType from '@/components/Input/BoisType.vue';
	import InputBoisQuality from '@/components/Input/BoisQuality.vue';
	import InputBoisSize from '@/components/Input/BoisSize.vue';
	import InputMarquage from '@/components/Input/Marquage.vue';

	@Component({
		components: {
			InputBoisType,
			InputBoisQuality,
			InputBoisSize,
			InputMarquage,
			LMap,
			LTileLayer,
			LMarker,
			LIcon,
			LControl,
			LTooltip,
			LPopup,
			LCircleMarker,
			'l-wms-tile-layer': LWMSTileLayer
		}
	})
	export default class PrestationMapView extends Vue {

		@Stored(() => storeToken) private token: Token;

		lat = 46.08014897989135;
		lng = 4.386728978266652;
		center;
		zoom = 13;
		url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		urlsat = 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';
		attribution =
			'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
		showMap = false;
		private showMapSatellite : boolean  = false;
		private showMapCadastre : boolean  = false;
		private opacitySat : number = 0;
		private opacityCadastre : number = 0;
		markers;
		subdomains = ["mt0", "mt1", "mt2", "mt3"];
		wmsLayer = {
        url: 'https://data.geopf.fr/wms-r/wms',
        name: 'Weather Data',
        visible: true,
		version : '1.3.0',
        format: 'image/png',
		request: 'GetCapabilities',
        layers: 'CADASTRALPARCELS.PARCELLAIRE_EXPRESS',
        transparent: true,
        attribution: 'Weather data &copy; 2012 <a href="https://mesonet.agron.iastate.edu/docs/nexrad_mosaic/">IEM Nexrad</a>',
      	};

		private filtreCommune = '';
		private filtreClient  = '';
		private filtreScierie = '';
		private filtreChauffeur  = '';

		private grumetext  = '1';
		private billontext = '1';
		private avec_toustext  = '1';
		private avec_autrestext = '1';
		private chauffeurtext = '';
		private scierietext = '';
		private chantiertext = '';
		private typeboistext = '';
		private qualiteboistext = '';
		private tailleboistext = '';
		private marquagetext = '';
		private bois_vendutext = '1';
		private bois_non_vendutext = '1';

		@Stored(() => storeCubageChantierRestantForSearchChantier) private filters!: CubageChantierRestantForSearchChantierFilter;

		async mounted() {
			this.LoadData();
			this.center = latLng(this.lat, this.lng);
		}

		@WatchObjectProperties('filters', CubageChantierRestantForSearchChantierFilter)
		@Timeout()
		private filtersWatcher(): void {
			this.LoadData();
		}

		@Watch('showMapSatellite')
		onOpacitySatChanged(value: boolean, oldValue: boolean) {
			if (this.showMapSatellite == true)
				this.opacitySat = 1;
			else
				this.opacitySat = 0;
		}
		
		@Watch('showMapCadastre')
		onOpacityCadChanged(value: boolean, oldValue: boolean) {
			if (this.showMapCadastre == true)
				this.opacityCadastre = 1;
			else
				this.opacityCadastre = 0;
		}

		private async CalculFiltre()
		{
			if (this.filters.bGrume == true)
				this.grumetext = '1';
			else
				this.grumetext = '0';

			if (this.filters.bBillon == true)
				this.billontext = '1';
			else
				this.billontext = '0';

			if (this.filters.boisVendu == true)
				this.bois_vendutext = '1';
			else
				this.bois_vendutext = '0';

			if (this.filters.boisNonVendu == true)
				this.bois_non_vendutext = '1';
			else
				this.bois_non_vendutext = '0';

			if (this.filters.bAvecTous == true)
				this.avec_toustext = '1';
			else
				this.avec_toustext = '0';

			if (this.filters.bAvecAutres == true)
				this.avec_autrestext = '1';
			else
				this.avec_autrestext = '0';

			if ((this.filters.chauffeur == '') || (this.filters.chauffeur == 'null') || (this.filters.chauffeur == null))
				this.chauffeurtext = 'NULL';
			else
				this.chauffeurtext = this.filters.chauffeur;

			if ((this.filters.scierie == '') || (this.filters.scierie == 'null') || (this.filters.scierie == null))
				this.scierietext = 'NULL';
			else
				this.scierietext = this.filters.scierie;

			if ((this.filters.chantier == '') || (this.filters.chantier == 'null') || (this.filters.chantier == null))
				this.chantiertext = 'NULL';
			else
				this.chantiertext = this.filters.chantier;

			if (this.filters.boisType)
				this.typeboistext = this.filters.boisType.id.toString();
			else
				this.typeboistext = 'NULL';

			if (this.filters.boisQuality)
				this.qualiteboistext = this.filters.boisQuality.id.toString();
			else
				this.qualiteboistext = 'NULL';
		
			if (this.filters.boisSize)
				this.tailleboistext = this.filters.boisSize.id.toString();
			else
				this.tailleboistext = 'NULL';

			if (this.filters.marquage)
				this.marquagetext = this.filters.marquage.id.toString();
			else
				this.marquagetext = 'NULL';
		}

		private async LoadData()
		{
			this.CalculFiltre();

			await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/chantiers/map_boisrestants/" + this.grumetext + "/" + this.billontext + "/" + this.avec_toustext + "/" + this.avec_autrestext + "/" + this.chauffeurtext + "/" + this.scierietext + "/" + this.chantiertext + "/" + this.typeboistext + "/" + this.qualiteboistext + "/" + this.tailleboistext + "/" + this.marquagetext + "/" + this.bois_vendutext + "/" + this.bois_non_vendutext + "?token=" + this.token.id, {}).then(async response => {
				this.markers = await response.data.data;
			});

			this.$forceUpdate();
		}


		private ReloadData() : void {
			this.LoadData();
		}

	}
