
















































































	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {TiersType, CubagePlateformeRestant, User, BoisSize} from '@/models';
	import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';

	import storeCubagePlateformeRestant from '@/stores/modules/cubagePlateformeRestant';
    import validator from "@/shared/validator";

	import InputBoisType from '@/components/Input/BoisType.vue';
	import InputBoisQuality from '@/components/Input/BoisQuality.vue';
	import InputBoisSize from '@/components/Input/BoisSize.vue';
	import InputMarquage from '@/components/Input/Marquage.vue';
	import {Stored} from 'vue-stored-prop-decorator/index';
	import storeUser from '@/stores/modules/user';
    import CrudChauffeurSearch from '@/components/Crud/Chauffeur/Search.vue';

	@Component({
		components: {
			InputBoisType,
			InputBoisQuality,
			InputBoisSize,
			InputMarquage,
			CrudTiersSearch,
			CrudChauffeurSearch,
		}
	})
	export default class CrudCubagePlateformeRestantEditPourLivraison extends AbstractEditVue<CubagePlateformeRestant> {

        validator = validator
		private TiersType = TiersType;

		public constructor() {
			super(
				storeCubagePlateformeRestant,
				'Cubage modifié',
				'Cubage créé',
			);
		}

		public async mounted(): Promise<void> {
			await super.mounted();
		
		}

}
