import Vue from 'vue';
import Vuex from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {SuiviAutresReglement, Tiers} from '@/models';
import {SuiviAutresReglementService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

export class SuiviAutresReglementFilter {
	
	reference: string = '';
	tiers: Tiers[] = [];
	
	public clear(): void {
		this.reference = '';
		this.tiers = [];
	}

	public toString(): string {
		return JSON.stringify({
			reference: this.reference,
			tiers    : this.tiers.map(t => t.id),
		});
	}
}

class SuiviAutresReglementState extends PaginatorFilterState<SuiviAutresReglementFilter> {
	public suiviAutresReglement: SuiviAutresReglement = null;
	public suiviAutresReglements: ResultType<SuiviAutresReglement> = null;
	public constructor() {
		super(new SuiviAutresReglementFilter(), 'suiviAutresReglements', 'suiviAutresReglement', 'id', AscDesc.DESC, 50);
	}
}

class SuiviAutresReglementStore {
	
	@XHTTPService(() => SuiviAutresReglement)
	private suiviAutresReglementService: SuiviAutresReglementService;
	
	public state: SuiviAutresReglementState = new SuiviAutresReglementState();
	
	public mutations = {
		...PaginatorMutationFilter(() => storeSuiviAutresReglement, 'suiviAutresReglements', 'suiviAutresReglement'),
	};
	
	public actions = {
		...PaginatorActionWithFilters<SuiviAutresReglement, SuiviAutresReglementState>(() => SuiviAutresReglement, 'suiviAutresReglements', 'suiviAutresReglement'),
		...StandardAction<SuiviAutresReglement, SuiviAutresReglementState>(() => SuiviAutresReglement, 'suiviAutresReglement'),
	};
	
}
const instance = new SuiviAutresReglementStore();
const storeSuiviAutresReglement = new Vuex.Store(instance);
store.registerModule('suiviAutresReglement', storeSuiviAutresReglement);
export default storeSuiviAutresReglement;
