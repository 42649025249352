

















































































































































































































































































































































































































































































































































































	import {Stored} from 'vue-stored-prop-decorator';
	import {Component, Vue} from '@/shared/component';
	import {Emit} from 'vue-property-decorator';
	import {ResultType} from '@/shared/xhttp';
	import axios from "axios";
	import CrudList from '@/components/Crud/List.vue';
	import storeToken from "@/stores/modules/token";
	import {BoisSize, CubagePlateformeRestant, Plateforme, Token, User} from "@/models"; 
	import DialogConfirm from '@/components/Dialog/Confirm.vue';
	import InputBoisType from '@/components/Input/BoisType.vue';
	import InputBoisQuality from '@/components/Input/BoisQuality.vue';
	import InputBoisSize from '@/components/Input/BoisSize.vue';
	import InputMarquage from '@/components/Input/Marquage.vue';
	import storeCubageChantierRestantForSearchChantier, {CubageChantierRestantForSearchChantierFilter} from "@/stores/modules/cubageChantierRestantForSearchChantier";
	import {Prop, Watch} from "vue-property-decorator";
	import {Timeout, WatchObjectProperties} from '@/shared/decorator';
	import storeBoisType from "@/stores/modules/boisType";
	import storeBoisQuality from "@/stores/modules/boisQuality";
	import storeBoisSize from "@/stores/modules/boisSize";
	import CrudCubageChantierEditPourLivraisonDialog from '@/components/Crud/CubageChantier/EditDialogPourLivraison.vue';
	import CrudCubagePlateformeRestantEditPourLivraisonDialog from '@/components/Crud/CubagePlateformeRestant/EditDialogPourLivraison.vue';
	import CrudChantierEditChauffeurDialog from '@/components/Crud/Chantier/EditDialogChauffeur.vue';
	import {CubageChantier, CubageLivraison, CubageLivraisonPlateforme, Livraison, CubageChantierRestant} from "@/models";
	import {Chantier} from "@/models";
	import storeCubageChantier from '@/stores/modules/cubageChantier';
	import storeChantier from '@/stores/modules/chantier';

	import CrudChantierDocumentChauffeursEditDialog from '@/components/Crud/Chantier/EditDocumentChauffeursDialog.vue';
	import CrudLivraisonEditDialogCreate from '@/components/Crud/Livraison/EditDialogCreate.vue';
	import CrudCubageLivraisonEditDialogCreate from '@/components/Crud/CubageLivraison/EditDialogCreate.vue';
	import CrudCubageChantierEditDialog from '@/components/Crud/CubageChantier/EditDialog.vue';
	import CrudLivraisonPlateformePourChauffeurEditDialogList from '@/components/Crud/LivraisonPlateformePourChauffeur/EditDialogList.vue';
	import storeLivraison from '@/stores/modules/livraison';
	import {PaginatorState} from '@/shared/utils';
	import CrudChantierRapideEditDialog from '@/components/Crud/Chantier/EditChantierRapideDialog.vue';
	import CrudCubageChantierVolumeRestantEdit from '@/components/Crud/CubageChantierRestant/EditVolumeRestant.vue';
	import CrudCubagePlateformeVolumeRestantEdit from '@/components/Crud/CubagePlateformeRestant/EditVolumeRestant.vue';
	import storeCubagePlateforme from '@/stores/modules/cubagePlateforme';
	import storeCubagePlateformeRestant, {CubagePlateformeRestantFilter} from '@/stores/modules/cubagePlateformeRestant';

	import CrudCubageLivraisonPlateformeEditCreateDialog from "@/components/Crud/CubageLivraisonPlateforme/EditDialogCreate.vue";

	@Component({
		components: {
			CrudCubageChantierVolumeRestantEdit,
			CrudCubagePlateformeVolumeRestantEdit,
			CrudList,
			DialogConfirm,
			InputBoisType,
			InputBoisQuality,
			CrudLivraisonEditDialogCreate,
			InputBoisSize,
			InputMarquage,
			CrudCubageChantierEditPourLivraisonDialog,
			CrudChantierEditChauffeurDialog,
			CrudCubageLivraisonEditDialogCreate,
			storeCubageChantier,
			storeChantier,
			storeLivraison,
			CrudChantierDocumentChauffeursEditDialog,
			CrudCubageChantierEditDialog,
			CrudLivraisonPlateformePourChauffeurEditDialogList,
			CrudChantierRapideEditDialog,
			CrudCubagePlateformeRestantEditPourLivraisonDialog,
			CrudCubageLivraisonPlateformeEditCreateDialog,
		}
	})
	export default class SuiviLivraisonView extends Vue {
		@Stored(() => storeToken) private token: Token;
		@Stored(() => storeCubagePlateformeRestant) private filtersCubagePlateformeRestant!: CubagePlateformeRestantFilter;

		//private storeCubageChantierRestant = storeCubageChantierRestant;
		private setAucunChauffeur: number = 0;
		private setTousChauffeurs: number = 0;
		private setAucunChauffeurPlateforme: number = 0;
		private setTousChauffeursPlateforme: number = 0;
		
		private setLivraisonUrgente: number = 0;
		private annulerLivraisonUrgente: number = 0;
		private setLivraisonPlateformeUrgente: number = 0;
		private annulerLivraisonPlateformeUrgente: number = 0;

		private data = '';
		
		private grumetext  = '1';
		private billontext = '1';
		private avec_toustext  = '1';
		private avec_autrestext = '1';
		private chauffeurtext = '';
		private scierietext = '';
		private chantiertext = '';
		private typeboistext = '';
		private qualiteboistext = '';
		private tailleboistext = '';
		private marquagetext = '';
		private bois_vendutext = '1';
		private bois_non_vendutext = '1';

		private editCubageChantier: CubageChantier = null;
		private editCubagePlateformeRestant: CubagePlateformeRestant = null;
		private editNewCubageChantier: CubageChantier = null;
		private editChantier: Chantier = null;
		private editChantierDocumentPourChauffeur: Chantier = null;
		private editChantierRapide: Chantier = null;
		private editCubageChantierChangeVolumeRestant: CubageChantierRestant = null;
		private editCubagePlateformeChangeVolumeRestant: CubagePlateformeRestant = null;

		private editLivraison: Livraison = null;
		private editCubageLivraison: CubageLivraison = null;
		private editCubageChantierRestant: Object= null

		private editLivraisonPlateforme: Livraison = null;
		private createCubagePlateformeRestantPourLivraison: Object= null

		private bExportPDF: boolean = false;

		private headers = [
			{ text: 'Modifier', value: 'modifier', groupable: false, sortable: false },
			{ text: 'Chauffeur', value: 'transporteur_par_defaut', groupable: false, sortable: false },
			{ text: 'Débardeur', value: 'debardeur', groupable: false, sortable: false },
			{ text: 'Date débardage', value: 'date_fin_debardage', groupable: false, sortable: false },
			{ text: 'Scierie', value: 'scierie', groupable: false, sortable: false },
			{ text: 'Liens', value: 'liens', groupable: false, sortable: false },
			{ text: 'Bois', value: 'bois', groupable: false, sortable: false },
			{ text: 'Volume restant', value: 'volume', groupable: false, sortable: false },
			{ text: 'Vendu', value: 'boisVendu', groupable: false, sortable: false },
			{ text: 'Actions', value: 'livrer', groupable: false, sortable: false },
			{ text: 'Remarques', value: 'message_bois', groupable: false, sortable: false },
		];

		@Stored(() => storeCubageChantierRestantForSearchChantier) private filters!: CubageChantierRestantForSearchChantierFilter;

		@Emit()
		private onCreateChantierRapide(): void {
			this.editChantierRapide = new Chantier();
			this.editChantierRapide.name = 'CH-RAPIDE';
		}

		@WatchObjectProperties('filters', CubageChantierRestantForSearchChantierFilter)
		@Timeout()
		private filtersWatcher(): void {
			this.LoadData();
		}

		private ClearChauffeur() : void {
			this.filters.chauffeur = '';
			this.LoadData();
		}

		private ClearScierie() : void {
			this.filters.scierie = '';
			this.LoadData();
		}

		private ClearChantier() : void {
			this.filters.chantier = '';
			this.LoadData();
		}

		private async CallEditChantier(ID : number): Promise<void> 
		{
			this.editChantier = await storeChantier.dispatch('get', ID);
		}		
		
		private async editVolumeRestant(id_cubage_chantier_restant: number, grume: string): Promise<void> 
		{
			
			var Obj = new CubageChantierRestant();
			Obj.id = id_cubage_chantier_restant;
			Obj.boisSize = new BoisSize();
			if (grume == '0')
			{
				Obj.boisSize.name = "Steres";
			}
			else
			{
				Obj.boisSize.name = "M3";
			}

			this.editCubageChantierChangeVolumeRestant = Obj;
		}		
		
		private async editVolumeRestantPlateforme(id_cubage_plateforme_restant: number, grume: string): Promise<void> 
		{
			
			var Obj = new CubagePlateformeRestant();
			Obj.id = id_cubage_plateforme_restant;
			Obj.boisSize = new BoisSize();
			if (grume == '0')
			{
				Obj.boisSize.name = "Steres";
			}
			else
			{
				Obj.boisSize.name = "M3";
			}

			//console.log(Obj);
			this.editCubagePlateformeChangeVolumeRestant = Obj;
		}		

		private async editCubage(ID : number): Promise<void> 
		{
			this.editCubageChantier = await storeCubageChantier.dispatch('get', ID);
		}		
		
		private async editCubagePlateforme(ID : number): Promise<void> 
		{
			this.editCubagePlateformeRestant = await storeCubagePlateformeRestant.dispatch('get', ID);
		}		

		private async editDocumentPourChauffeur(ID : number): Promise<void> 
		{
			this.editChantierDocumentPourChauffeur = await storeChantier.dispatch('get', ID);
		}		

		private async CreateCubageChantier(ID : number): Promise<void> 
		{
			var oChantier = await storeChantier.dispatch('get', ID);

			this.editNewCubageChantier = new CubageChantier();
			this.editNewCubageChantier.chantier = oChantier;
			this.editNewCubageChantier.chauffeurParDefaut = oChantier.chauffeurParDefaut;
			this.editNewCubageChantier.coef = 0.9;
			this.editNewCubageChantier.coefStere = 0.63;
			this.editNewCubageChantier.coutM3 = oChantier.coutM3Defaut;
		}		

		private async cubageSetAucunChauffeur(): Promise<void> {
			await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-chantiers/enlever_tous_chauffeurs/" + this.setAucunChauffeur + "?token=" + this.token.id, {});
			this.LoadData();
		}

		private async cubageSetTousChauffeurs(): Promise<void> {
			await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-chantiers/affecter_tous_chauffeurs/" + this.setTousChauffeurs + "?token=" + this.token.id, {});
			this.LoadData();
		}

		private async cubagePlateformeSetAucunChauffeur(): Promise<void> {
			await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-plateforme-restants/enlever_tous_chauffeurs/" + this.setAucunChauffeurPlateforme + "?token=" + this.token.id, {});
			this.LoadData();
		}

		private async cubagePlateformeSetTousChauffeurs(): Promise<void> {
			await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-plateforme-restants/affecter_tous_chauffeurs/" + this.setTousChauffeursPlateforme + "?token=" + this.token.id, {});
			this.LoadData();
		}

		private async AnnulerLivraisonUrgente(cubageRestant: Object) {
			await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-chantiers/annulerLivraisonUrgente/" + this.annulerLivraisonUrgente + "?token=" + this.token.id, {});
			this.LoadData();
		}

		private async livraisonUrgente(cubageRestant: Object) {
			await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-chantiers/livraisonUrgente/" + this.setLivraisonUrgente + "?token=" + this.token.id, {});
			this.LoadData();
		}

		private async AnnulerLivraisonPlateformeUrgente(cubageRestant: Object) {
			await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-plateforme-restants/annulerLivraisonUrgente/" + this.annulerLivraisonPlateformeUrgente + "?token=" + this.token.id, {});
			this.LoadData();
		}

		private async livraisonPlateformeUrgente(cubageRestant: Object) {
			await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-plateforme-restants/livraisonUrgente/" + this.setLivraisonPlateformeUrgente + "?token=" + this.token.id, {});
			this.LoadData();
		}

		private async mounted(): Promise<void> {
			await Promise.all([
				(async () => storeBoisType   .state.boisTypesAll || await storeBoisType   .dispatch('getAll'))(),
				(async () => storeBoisQuality.state.boisQualitiesAll || await storeBoisQuality.dispatch('getAll'))(),
				(async () => storeBoisSize   .state.boisSizesAll || await storeBoisSize   .dispatch('getAll'))(),
			]);

			this. LoadData();
		}

		private async CalculFiltre()
		{
			if (this.filters.bGrume == true)
				this.grumetext = '1';
			else
				this.grumetext = '0';

			if (this.filters.bBillon == true)
				this.billontext = '1';
			else
				this.billontext = '0';

			if (this.filters.boisVendu == true)
				this.bois_vendutext = '1';
			else
				this.bois_vendutext = '0';

			if (this.filters.boisNonVendu == true)
				this.bois_non_vendutext = '1';
			else
				this.bois_non_vendutext = '0';

			if (this.filters.bAvecTous == true)
				this.avec_toustext = '1';
			else
				this.avec_toustext = '0';

			if (this.filters.bAvecAutres == true)
				this.avec_autrestext = '1';
			else
				this.avec_autrestext = '0';

			if ((this.filters.chauffeur == '') || (this.filters.chauffeur == 'null') || (this.filters.chauffeur == null))
				this.chauffeurtext = 'NULL';
			else
				this.chauffeurtext = this.filters.chauffeur;

			if ((this.filters.scierie == '') || (this.filters.scierie == 'null') || (this.filters.scierie == null))
				this.scierietext = 'NULL';
			else
				this.scierietext = this.filters.scierie;

			if ((this.filters.chantier == '') || (this.filters.chantier == 'null') || (this.filters.chantier == null))
				this.chantiertext = 'NULL';
			else
				this.chantiertext = this.filters.chantier;

			if (this.filters.boisType)
				this.typeboistext = this.filters.boisType.id.toString();
			else
				this.typeboistext = 'NULL';

			if (this.filters.boisQuality)
				this.qualiteboistext = this.filters.boisQuality.id.toString();
			else
				this.qualiteboistext = 'NULL';
		
			if (this.filters.boisSize)
				this.tailleboistext = this.filters.boisSize.id.toString();
			else
				this.tailleboistext = 'NULL';

			if (this.filters.marquage)
				this.marquagetext = this.filters.marquage.id.toString();
			else
				this.marquagetext = 'NULL';
		}

		private async LoadData()
		{
			console.log("Load data");
			this.CalculFiltre();

			await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-chantier-restants/synthese/" + this.grumetext + "/" + this.billontext + "/" + this.avec_toustext + "/" + this.avec_autrestext + "/" + this.chauffeurtext + "/" + this.scierietext + "/" + this.chantiertext + "/" + this.typeboistext + "/" + this.qualiteboistext + "/" + this.tailleboistext + "/" + this.marquagetext + "/" + this.bois_vendutext + "/" + this.bois_non_vendutext + "?token=" + this.token.id, {}).then(async response => {
				this.data = await response.data;
			});
		}

		private async AvantExporterPDF(): Promise<void> {
			this.CalculFiltre();
			window.open(`${process.env.VUE_APP_BACK_BASE_URL}/pdf/suivi_livraison/${ this.grumetext }/${ this.billontext }/${ this.avec_toustext }/${ this.avec_autrestext }/${ this.chauffeurtext }/${ this.scierietext }/${ this.chantiertext }/${ this.typeboistext }/${ this.qualiteboistext }/${ this.tailleboistext }/${ this.marquagetext }/${ this.bois_vendutext }/${ this.bois_non_vendutext }?token=${this.token.id}`, '_blank');


/*				await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/livraison-plateforme-pour-chauffeur/clear?token=" + this.token.id, {});
			var oDlg = this.$refs['listPlateforme'] as CrudLivraisonPlateformePourChauffeurEditDialogList;
			oDlg.RefreshData();
			console.log("Delete all");
			this.bExportPDF = true;*/
		}

		private ExportPDF()
		{
			var oDlg = this.$refs['listPlateforme'] as CrudLivraisonPlateformePourChauffeurEditDialogList;
			if (oDlg.result == true )
			{
				this.CalculFiltre();
				window.open(`${process.env.VUE_APP_BACK_BASE_URL}/pdf/suivi_livraison/${ this.grumetext }/${ this.billontext }/${ this.avec_toustext }/${ this.avec_autrestext }/${ this.chauffeurtext }/${ this.scierietext }/${ this.chantiertext }/${ this.typeboistext }/${ this.qualiteboistext }/${ this.tailleboistext }/${ this.marquagetext }/${ this.bois_vendutext }/${ this.bois_non_vendutext }?token=${this.token.id}`, '_blank');
			}
		}

		/*private get exportPDFURL(): string {
			this.CalculFiltre();

			return `${process.env.VUE_APP_BACK_BASE_URL}/pdf/suivi_livraison/${ this.grumetext }/${ this.billontext }/${ this.avec_toustext }/${ this.avec_autrestext }/${ this.chauffeurtext }/${ this.scierietext }/${ this.chantiertext }/${ this.typeboistext }/${ this.qualiteboistext }/${ this.tailleboistext }/${ this.marquagetext }/${ this.bois_vendutext }/${ this.bois_non_vendutext }?token=${this.token.id}`;
		}*/

		formatDate(dateString) {
			if (dateString == '') return '';
			if (dateString == null) return '';

            const date = new Date(dateString);
                // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', { month: 'long', day: 'numeric', year: 'numeric', }).format(date);
        }

		itemRowBackground (item){
			if (item.boisVendu == 1 )
			{
				return item.grume == 1 ? 'grume_vendu_class' : 'billon_vendu_class';
			}
			else
			{
				return item.grume == 1 ? 'grume_class' : 'billon_class';
			}
		}

		private async createLivraisonFromCubageChantier(cubageRestant: Object) {
			var oCubageChantier = await storeCubageChantier.dispatch('get', cubageRestant["id_cubage_chantier"]);

			this.editCubageChantierRestant = cubageRestant;
			//console.log(oCubageChantier);
			this.editLivraison = new Livraison();
			this.editLivraison.scierie = oCubageChantier.scierie;
			this.editLivraison.chauffeur = oCubageChantier.chauffeurParDefaut;
		}
		
		private AddCubageLivraison()
		{
			// On va chercher notre Livraison dans le store
			var oCubageChantier = (storeCubageChantier.state as PaginatorState).current; // await storeCubageChantier.dispatch('get', cubageRestant["id_cubage_chantier"]);
			//console.log(oCubageChantier);
			var oLivraison  = (storeLivraison.state as PaginatorState).current;
			storeLivraison.state.CounterCreation = 1;
			this.editCubageLivraison = new CubageLivraison();
			//console.log(oLivraison);

			this.editCubageLivraison.date = oLivraison.date;
			this.editCubageLivraison.livraison = oLivraison;
			this.editCubageLivraison.bl = oLivraison.bl;
			this.editCubageLivraison.numfacture = oLivraison.numfacture;
			this.editCubageLivraison.scierie = oLivraison.scierie;
			this.editCubageLivraison.chauffeur = oLivraison.chauffeur;

			this.editCubageLivraison.chantier = oCubageChantier.chantier;
			this.editCubageLivraison.boisType = oCubageChantier.boisType;
			this.editCubageLivraison.boisQuality = oCubageChantier.boisQuality;
			this.editCubageLivraison.boisSize = oCubageChantier.boisSize;
			this.editCubageLivraison.marquage = oCubageChantier.marquage;
			if (this.editCubageLivraison.marquage == null) {
				this.editCubageLivraison.sansMarquage = true;
			}
			this.editCubageLivraison.coef = 0.9;
			this.editCubageLivraison.coefStere = 0.63;
			this.editCubageLivraison.boisFini = false;

			if (this.editCubageChantierRestant["grume"]  == 1)
			{
				this.editCubageLivraison.volumeEstimeRestant = this.editCubageChantierRestant["volume"] + " m3";
			}
			else
			{
				this.editCubageLivraison.volumeEstimeRestant = this.editCubageChantierRestant["volume_stere"] + " stères";
			}			
		}

		private async createLivraisonFromCubagePlateformeRestant(cubageRestant: Object) {
			var oCubageChantier = await storeCubagePlateformeRestant.dispatch('get', cubageRestant["id_cubage"]);
			// On filtre sur la bonne plateforme
		    this.filtersCubagePlateformeRestant = new CubagePlateformeRestantFilter();
			var oPlat = new Plateforme();
			oPlat.id = cubageRestant["plateforme_id"];
			storeCubagePlateformeRestant.state.filters.plateforme = [ oPlat ];
			await storeCubagePlateformeRestant.dispatch('getC', { filters: true })

			this.editLivraisonPlateforme = new Livraison();
			this.editLivraisonPlateforme.scierie = oCubageChantier.scierie;
			this.editLivraisonPlateforme.chauffeur = oCubageChantier.chauffeurParDefaut;

		}

		private AddCubageLivraisonPlateforme()
		{
			// On va chercher notre Livraison dans le store
			var oCubagePlateformeRestantPourLivraison = (storeCubagePlateformeRestant.state as PaginatorState).current; // await storeCubageChantier.dispatch('get', cubageRestant["id_cubage_chantier"]);
			//console.log(oCubageChantier);
			var oLivraison  = (storeLivraison.state as PaginatorState).current;
			storeLivraison.state.CounterCreation = 1;

			let cubage = new CubageLivraisonPlateforme();
			cubage.plateforme = oCubagePlateformeRestantPourLivraison.plateforme;
			cubage.date = oLivraison.date;
			cubage.livraison = oLivraison;
			cubage.bl = oLivraison.bl;
			cubage.numfacture = oLivraison.numfacture;
			cubage.scierie = oLivraison.scierie;
			cubage.chauffeur = oLivraison.chauffeur;
			cubage.coef =0.9;
			cubage.coefStere =0.63;

			cubage.boisType = oCubagePlateformeRestantPourLivraison.boisType;
			cubage.boisQuality = oCubagePlateformeRestantPourLivraison.boisQuality;
			cubage.boisSize = oCubagePlateformeRestantPourLivraison.boisSize;
			cubage.case = oCubagePlateformeRestantPourLivraison.case;

			console.log(cubage);

			this.createCubagePlateformeRestantPourLivraison = cubage;
		}


		private AddEncoreCubageLivraison()
		{
			if (storeLivraison.state.nextcubage_meme_chantier == true)
		{
			storeLivraison.state.CounterCreation += 1;
			var oLivraison  = (storeLivraison.state as PaginatorState).current;
			this.editCubageLivraison = new CubageLivraison();

			this.editCubageLivraison.date = oLivraison.date;
			this.editCubageLivraison.chantier = storeLivraison.state.PreviousCubageLivraison.chantier;
			this.editCubageLivraison.livraison = oLivraison;
			this.editCubageLivraison.bl = oLivraison.bl;
			this.editCubageLivraison.numfacture = oLivraison.numfacture;
			this.editCubageLivraison.scierie = oLivraison.scierie;
			this.editCubageLivraison.chauffeur = oLivraison.chauffeur;
			this.editCubageLivraison.coef = 0.9;
			this.editCubageLivraison.coefStere = 0.63;
			this.editCubageLivraison.boisFini = false;
		}
		else
		{
			if (storeLivraison.state.nextcubage_autre_chantier == true)
			{
				storeLivraison.state.CounterCreation += 1;
				var oLivraison  = (storeLivraison.state as PaginatorState).current;
				this.editCubageLivraison = new CubageLivraison();

				this.editCubageLivraison.date = oLivraison.date;
				this.editCubageLivraison.livraison = oLivraison;
				this.editCubageLivraison.bl = oLivraison.bl;
				this.editCubageLivraison.numfacture = oLivraison.numfacture;
				this.editCubageLivraison.scierie = oLivraison.scierie;
				this.editCubageLivraison.chauffeur = oLivraison.chauffeur;
				this.editCubageLivraison.coef = 0.9;
				this.editCubageLivraison.coefStere = 0.63;
				this.editCubageLivraison.boisFini = false;
			}
		}			
		}
	}
